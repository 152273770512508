<template>
  <v-dialog
    :value="value"
    @click:outside="$emit('input', false)"
    @input="v => $emit('input', v)"
  >
    <v-card class="px-5 py-5">
      <h3 class="py-3">
        إعادة إسناد المعاملة رقم {{ reassignData.transaction_id }}
      </h3>
      <v-divider class="mb-5" />
      <v-row>
        <v-col>
          <p>المدخل</p>
          <v-select
            v-model="form.add_by"
            outlined
            item-text="name"
            item-value="id"
            :items="usersList"
          />
        </v-col>
        <v-col>
          <p>المقيم</p>
          <v-select
            v-model="form.resident_id"
            outlined
            item-text="name"
            item-value="id"
            :items="usersList"
          />
        </v-col>
        <v-col>
          <p>المراجع</p>
          <v-select
            v-model="form.reviewer_id"
            outlined
            :items="usersList"
            item-text="name"
            item-value="id"
          />
        </v-col>
        <v-col>
          <p>المعمد</p>
          <v-select
            v-model="form.approved_id"
            :items="usersList"
            item-text="name"
            item-value="id"
            outlined
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row>
          <v-col offset="10">
            <v-btn
              color="primary"
              block
              :loading="loading"
              :disabled="loading"
              @click="update"
            >
              حفظ
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { ServiceFactory } from '../../../../services/ServiceFactory'
  const Transactions = ServiceFactory.get('Transactions')
  export default {
    props: ['value', 'usersList', 'reassignData'],
    data: (vm) => ({
      form: {
        add_by: vm.$props.reassignData.add_by,
        resident_id: vm.$props.reassignData.resident_id,
        reviewer_id: vm.$props.reassignData.reviewer_id,
        approved_id: vm.$props.reassignData.reviewer_id,
      },
      loading: false,
    }),
    watch: {
      'reassignData.id': {
        handler () {
          this.form = {
            add_by: this.reassignData.add_by,
            resident_id: this.reassignData.resident_id,
            reviewer_id: this.reassignData.reviewer_id,
            approved_id: this.reassignData.reviewer_id,
          }
        },
      },
    },
    methods: {
      async update () {
        this.loading = true
        await Transactions.reassign(this.reassignData.id, this.form)
        this.loading = false
        this.$emit('input', false)
        this.$emit('load', true)
      },
    },
  }
</script>
